import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';

@Component({
  selector: 'app-form-add-client',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    SelectCountryPrefixComponent,
  ],
  templateUrl: './form-add-client.component.html',
  styleUrl: './form-add-client.component.css',
})
export class FormAddClientComponent {
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() addClient = new EventEmitter<boolean>();

  form: FormGroup;

  clients: Array<{
    name: string;
    reference: string;
    tax_id: string;
    lastname: string;
  }> = [];
  filteredClients: Array<{ name: string; reference: string; tax_id: string }> =
    [];

  search$ = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService
  ) {
    this.form = this.fb.group({
      status: [true],
      type: ['', Validators.required],
      tax_id: ['', Validators.required],
      assignee_id: [''],
      name: ['', Validators.required],
      lastname: [''],
      email: ['', Validators.required],
      phone: [''],
      billing_address: [''],
      billing_postal_code: ['', [Validators.pattern(/^[0-9]+$/)]],
      billing_city: [''],
      billing_state: [''],
      phoneNumber: ['',  Validators.required],
      phonePrefix: ['+34'],
    });
  }

  ngOnInit(): void {
    this.loadIndividualClients();

     /* this.search$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.searchClients(term))
      )
      .subscribe((results) => {
        this.filteredClients = results;
      });
  */
      this.search$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((term) => {

         this.fetchClient(term);

      });

   // this.filteredClients = [];

    this.form.get('type')?.valueChanges.subscribe((typeValue) => {
    if (typeValue === 'organization') {
      this.form.get('lastname')?.reset();
    }
  });
  }

  fetchClient(term:string): void {
      this.clientService.searchClients(term).subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          // Crear displayName para cada cliente
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });


}
  emitAddClient() {
    this.addClient.emit(true);
  }

  onSubmit() {
    this.alertService.loading('swal_messages.creating_client');
    /* if (this.form.valid) { */
    let formData = { ...this.form.value };
    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value;
      if (!phonePrefix) {
        phonePrefix = '+34';
      }
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;

      this.form.get('phone')?.setValue(combinedPhoneNumber);
      formData = { ...this.form.value, phone: combinedPhoneNumber };
    }

    this.clientService.addData(formData).subscribe(
      (response) => {
        this.alertService.successOk('swal_messages.client_created');
        this.emitAddClient();
        this.loadIndividualClients();
        this.emitBackButtonClick();
        this.resetForm();
      },
      (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    );
    /* } else {
      this.alertService.error('swal_messages.incomplete_fields');
    } */
  }

  resetForm(): void {
    this.form.reset({
      status: true,
      type: '',
      tax_id: '',
      assignee_id: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_state: '',
       phoneNumber: '',
       phonePrefix: '+34',
    });
      this.changePrefix('+34');
  }

  changePrefix(event: any) {
    this.form.get('phonePrefix')?.setValue(event);
  }

  emitBackButtonClick() {
    this.resetForm();
    this.backButtonClick.emit(true);
  }

  getSelectedClientType(): string | null {
    return this.form.get('type')?.value || null;
  }

  get phone() {
    return this.form.get('phone');
  }

  nameValue: string | null = null;
  lastnameValue: string | null = null;
  individualClients: any[] = [];
  selectedClientType: string | null = null;

  loadIndividualClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        this.alertService.close();
        if (response.success) {
          const clientData: any[] = response.data;
          this.clients = clientData.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          // Crear displayName para cada cliente
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  searchClients(term: string): Observable<
    Array<{
      name: string;
      reference: string;
      tax_id: string;
      lastname?: string;
    }>
  > {
    return new Observable((observer) => {
      term = term || '';
      if (!term.trim()) {
        observer.next([]); // No devolver resultados si no hay término de búsqueda
      } else {
        const filtered = this.clients.filter((client) => {
          const nameMatches = client.name
            ? client.name.toLowerCase().includes(term.toLowerCase())
            : false;
          const taxIdMatches = client.tax_id
            ? client.tax_id.toLowerCase().includes(term.toLowerCase())
            : false;
          const fullnameMatches = client.lastname
            ? `${client.name} ${client.lastname}`
                .toLowerCase()
                .includes(term.toLowerCase())
            : false;
          return nameMatches || taxIdMatches || fullnameMatches;
        });
        observer.next(filtered);
      }
    });
  }

  onSearch(event: any): void {
    const term = event.term || '';
    this.search$.next(term);
  }


  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

}
