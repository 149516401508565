<form [formGroup]="generalForm">
  <div *ngIf="account_type == 'broker'">
    <div class="row">
      <div class="col-8">
        <div class="form-section">
          <div class="mb-3">
            <label for="broker_reference" class="form-label">{{
              "forms.inputs.reference" | translate
              }}</label>
            <div class="input-group div-copy">
              <input type="text" class="form-control" id="broker_reference" #inputBrokerReference
                formControlName="broker_reference" placeholder="{{
                  'forms.inputs.reference' | translate
                }}" required />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary copy" type="button"
                  (click)="copyToClipboard(inputBrokerReference.value,$event)">
                  <i class="icon-copy"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-section">
          <div class="mb-3">
            <label for="broker_agent_id" class="form-label">{{
              "forms.inputs.broker_agent" | translate
              }}</label>
            <select id="broker_agent_id" class="form-control" formControlName="broker_agent_id">
              <option value="" disabled selected>
                {{ "forms.inputs.select_option" | translate }}
              </option>
              <option *ngFor="let broker of agentData" [value]="broker.reference">
                {{ broker.name + " " + broker.lastname }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="account_type == 'supplier'">
    <div class="row">
      <div class="col-8">
        <div class="form-section">
          <div class="mb-3">
            <label for="supplier_reference" class="form-label">{{
              "forms.inputs.reference" | translate
              }}</label>
            <div class="input-group div-copy">
              <input type="text" class="form-control" id="supplier_reference"
                formControlName="supplier_reference" placeholder="{{
                  'forms.inputs.reference' | translate
                }}" required />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary copy" #inputSupplierReference type="button"
                  (click)="copyToClipboard(inputSupplierReference.value,$event)">
                  <i class="icon-copy"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-section">
          <div class="mb-3">
            <label for="supplier_agent_id" class="form-label">{{
              "forms.inputs.supplier_agent" | translate
              }}</label>
            <select id="supplier_agent_id" class="form-control" formControlName="supplier_agent_id">
              <option value="" disabled selected>
                {{ "forms.inputs.select_option" | translate }}
              </option>
              <option *ngFor="let supplier of agentData" [value]="supplier.reference">
                {{ supplier.name }} {{ supplier.lastname }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Vendor,sup,bro,dealer -->
  <div class="form-section">
    <div class="row">
      <div [ngClass]="draft ? 'col-4' : 'col-12'">
        <div class="mb-3">
          <label for="vendor" class="form-label">{{ "forms.inputs.vendor" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <select id="vendor" class="form-control" formControlName="vendor_id">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option *ngFor="let vendor of vendorData" [value]="vendor.reference">
              {{ vendor.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="vendor" class="form-label">{{ "forms.inputs.vendor_quote_reference" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="vendor_quote_reference" #inputVendorOfertReference
              formControlName="vendor_quote_reference"
              placeholder="{{ 'forms.inputs.vendor_quote_reference' | translate }}" required />

            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputVendorOfertReference.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="vendor" class="form-label">{{ "forms.inputs.vendor_reference" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="vendor_reference" #inputVendorReference
              formControlName="vendor_reference" placeholder="{{ 'forms.inputs.reference' | translate }}"
              required />

            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputVendorReference.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div *ngIf="account_type === 'broker'" class="row">
      <div [ngClass]="draft ? 'col-4' : 'col-12'">
        <div class="mb-3">
          <label for="supplier" class="form-label">{{ "forms.inputs.supplier" | translate }}
            <!--   <span class="required">*</span> --></label>
          <select id="supplier_id" class="form-control" formControlName="supplier_id">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option *ngFor="let supplier of supplierData" [value]="supplier.reference">
              {{ supplier.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="supplier" class="form-label">{{ "forms.inputs.supplier_reference" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="supplier_reference" #inputSupplierReference2
              formControlName="supplier_reference" placeholder="{{ 'forms.inputs.reference' | translate }}"
              required />

            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputSupplierReference2.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="supplier" class="form-label">{{ "forms.inputs.supplier_agent" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <input type="text" type="hidden" formControlName="supplier_agent_id"/>
          <input type="text" class="form-control" id="supplier_agent" formControlName="supplier_agent"
          placeholder="{{ 'forms.inputs.agent' | translate }}" required />

        </div>
      </div>
    </div>
    <div *ngIf="account_type === 'supplier'" class="row">
      <div class="col-4">
        <div class="mb-3">
          <label for="broker" class="form-label">{{ "forms.inputs.broker" | translate }}
            <!--   <span class="required">*</span> --></label>
          <select id="broker_id" class="form-control" formControlName="broker_id">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option *ngFor="let broker of brokerData" [value]="broker.reference">
              {{ broker.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="broker" class="form-label">{{ "forms.inputs.broker_reference2" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="broker_reference" #inputBrokerReference2
              formControlName="broker_reference" placeholder="{{ 'forms.inputs.reference' | translate }}"
              required />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputBrokerReference2.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="draft">
        <div class="mb-3">
          <label for="broker" class="form-label">{{ "forms.inputs.broker_agent" | translate }}
            <!--   <span class="required">*</span> -->
          </label>
          <input type="text" type="hidden" formControlName="broker_agent_id"/>
          <input type="text" class="form-control" id="broker_agent" formControlName="broker_agent"
            placeholder="{{ 'forms.inputs.reference' | translate }}" required />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="draft">
    <div class="form-section">
      <label for="stage" class="form-label">{{ "forms.inputs.stage" | translate }}
        <span class="required">*</span></label>
      <div class="row">
        <div class="col-6">
          <div class="mb-3">

            <select id="stage" class="form-control" formControlName="stage"
              (ngModelChange)="changeStage($event)">
              <option [value]="orderData.stage" selected>
                {{
                "forms.inputs.status_select." + orderData.stage
                | translate
                }}
              </option>
              <option *ngFor="let type of orderData.stages" [value]="type"
                [selected]="type === orderData.stage">
                {{
                "forms.inputs.status_select." + type | translate
                }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="btn-next">
            <button *ngIf="next_ok" type="submit" class="btn btn-primary btn-next_ok"
              (click)="onSubmitOrderFast(next_ok)"> <i class="icon-next-circle"></i>
              {{ "forms.inputs.status_select."+ next_ok | translate }}
            </button>

            <button *ngIf="next_ko" type="submit" class="btn btn-primary btn-next_ko"
              (click)="onSubmitOrderFast(next_ko)"> <i class="icon-pause-circle"></i>
              {{ "forms.inputs.status_select."+ next_ko | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label for="notes" class="form-label">{{
            "forms.inputs.addnote" | translate
            }}</label>
          <textarea class="form-control textarea150" id="notes" rows="5" formControlName="notes"></textarea>
        </div>
      </div>
    </div>
  </div>
</form>

