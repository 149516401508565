import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../../core/services/alert/alert.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
interface Item {
  date: string;
  created_by: string;
  message: string;
  created_by_name: string;
  content: string;
}

@Component({
  selector: 'app-form-add-notes',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  templateUrl: './form-add-notes.component.html',
  styleUrl: './form-add-notes.component.css',
})
export class FormAddNotesComponent implements OnChanges {
  @Output() backButtonClickDocument = new EventEmitter<boolean>();
  @Output() updateStage = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();
  @Input() order_reference?: any; // Objeto opcional
  @Input() activeForm?: any; // Objeto opcional
  form: FormGroup;
  notes: any[] = [];

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private orderService: OrdersService
  ) {
    this.form = this.fb.group({
      order_id: [''],
      public: [true],
      content: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order_reference']) {
      if (this.order_reference) {
        this.form.patchValue({
          order_id: this.order_reference,
        });
      }
    }
    if (changes['activeForm']) {
      if (this.activeForm === 'notes') {
        this.getNotes();
      }
    }
  }

    emitBackButtonClick() {
      this.order_reference = null;
      this.backButtonClickDocument.emit(true);
    }

    emitUpdateStage() {
      this.updateStage.emit(true);
    }
    onSubmit() {
      this.alertService.loading('swal_messages.please_wait_update_order');
      if (this.form.valid) {
        this.orderService.addNotes(this.form.value).subscribe({
          next: (data) => {
            this.alertService
              .successOk('swal_messages.add_notes_success')
              .then((result) => {
                if (result.isConfirmed) {
                  this.getNotes();
                  this.form.patchValue({
                    content: '',
                  });
                  this.emitUpdateStage();
                }
              });
          },
          error: (error) => {
            let message = error.error.message;
            this.alertService.error(error.error).then((result) => {
              if (result.isConfirmed) {
                // Puedes agregar acciones adicionales aquí si es necesario
              }
            });
          },
        });
      } else {
        this.alertService.warning('swal_messages.please_complete_form');
        this.alertService.close();
      }
    }
    getNotes() {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService.getNotes(this.order_reference).subscribe({
        next: (data: any) => {
          this.notes = data.data;
          this.notes.sort((a, b) => {
            return this.timeToNumber(a.date) - this.timeToNumber(b.date);
          });
          this.alertService.close();
        },
        error: (error: any) => { },
      });
    }

    timeToNumber(time: any) {
      return parseInt(time.replace(/:/g, ''));
    }
  }
