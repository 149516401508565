import { CommonModule, Location } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormAddDocumentComponent } from '../form-add-document/form-add-document.component';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-show-assignee',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    FormAddDocumentComponent,
    SelectCountryPrefixComponent,
  ],
  templateUrl: './form-show-assignee.component.html',
  styleUrl: './form-show-assignee.component.css',
})
export class FormShowAssigneeComponent {
  @Input() clientData?: any;
  @Input() selectTypes: any[] = [];
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() clientUpdated = new EventEmitter<any>();
  @Input() assignee?: any;
  activeForm = 'general';
  form: FormGroup;

  showExistingDocuments = true;

  clients: any[] = [];
  type = 'individual';
  isEditMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService,
    private location: Location,
    private titleService: Title
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      type: [''],
      phone: [''],
      billing_address: [''],
      billing_city: [''],
      billing_postal_code: [''],
      billing_state: [''],
      tax_id: ['', Validators.required],
      assignee_id: [''],
      assignee: [''],
      phonePrefix: [''],
      phoneNumber: ['', Validators.required],
    });
  }

  ngOnChanges(): void {
    this.activeForm = 'general';
    if (this.clientData) {
      this.isEditMode = true;
      this.type = this.clientData.type;

      const phone = this.clientData.phone || '';
      const phonePrefixMatch = phone.match(/^\(([^)]+)\)/);
      const phonePrefix = phonePrefixMatch ? phonePrefixMatch[1] : '+34';
      const phoneNumber = phone.replace(
        phonePrefixMatch ? phonePrefixMatch[0] : '',
        ''
      );


      this.form.patchValue({
        name: this.clientData.name || '',
        lastname: this.clientData.lastname || '',
        email: this.clientData.email || '',
        type: this.clientData.type || '',
        tax_id: this.clientData.tax_id || '',
        billing_address: this.clientData.billing_address || '',
        billing_city: this.clientData.billing_city || '',
        billing_postal_code: this.clientData.billing_postal_code || '',
        billing_state: this.clientData.billing_state || '',
        assignee_id: this.clientData.assignee?.reference || '',
        assignee: this.clientData.assignee?.reference || '',
        phonePrefix: phonePrefix,
        phoneNumber: phoneNumber,
      });

      this.form.get('type')?.disable();
      this.loadIndividualClients();

      this.titleService.setTitle(
        `Cliente ${this.clientData.name} ${this.clientData.lastname ? ` ${this.clientData.lastname}` : '' } - DBM`
      );
    }
  }

  emitBackButtonClick() {
    this.assignee = null;
    this.backButtonClick.emit(true);
  }

onSubmit() {
    this.alertService.loading('swal_messages.client_updated_loading');

    let formData = { ...this.form.value };
    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value;
      if (!phonePrefix) {
        phonePrefix = '+34';
      }
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;

      this.form.get('phone')?.setValue(combinedPhoneNumber);
      formData = { ...this.form.value, phone: combinedPhoneNumber };
    }


    this.clientService.updateClient(this.clientData.reference, formData).subscribe({
      next: (response) => {
        // Cerrar el loader y mostrar un mensaje de éxito cuando el cliente se actualiza correctamente
        this.alertService.close();
        this.alertService.successOk('swal_messages.client_updated');
        this.clientUpdated.emit();
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });
}

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  filteredClients: Array<{ name: string; reference: string; tax_id: string }> =
    [];

  loadIndividualClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        if (response.success) {
          this.clients = response.data;
          this.clients = this.clients.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));


          if (this.clientData.assignee) {
             let  singleClient = {
                ...this.clientData.assignee,
                displayName: `${this.clientData.assignee.name}${
                  this.clientData.assignee.lastname ? ` ${this.clientData.assignee.lastname}` : ''
                }`.trim(),
              };

              const clientExists = this.filteredClients.some(
                (client) => client.reference === singleClient.reference
              );
              if (!clientExists) {
                this.filteredClients.push(singleClient);
              }

          }
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }



  /** Documents **/
  docFiles: any[] = [];
  addDocumentComponent?: FormAddDocumentComponent;

  actualizarEstadoArchivos(nuevoArrayArchivos: any[]) {
    this.docFiles = nuevoArrayArchivos;
  }

  changeForm(form: string) {
    if (this.docFiles.length > 0) {
      this.alertService
        .confirmation2(
          'swal_messages.files_charged_message_' + this.activeForm,
          'swal_messages.files_charged',
          'swal_messages.confirm',
          'swal_messages.back'
        )
        .then((result) => {
          if (result.isConfirmed) {
            if (this.addDocumentComponent) {
              this.addDocumentComponent.actualizarArchivos([]);
            }
            this.activeForm = form;
          } else {
          }
        });
    } else {
      this.activeForm = form;
    }
  }
  changePrefix(newPrefix: string) {
    this.form.get('phonePrefix')?.setValue(newPrefix);
  }




  copyToClipboard(value: string, event: Event): void {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  getThumbnailSrc2(name: string): string {
    const patterns: { [key: string]: RegExp } = {
      '../../../assets/icons/jpg.svg': /\.(jpg|png)$/i,
      '../../../assets/icons/pdf.svg': /\.pdf$/i,
      '../../../assets/icons/word.svg': /\.(docx?|dotx?)$/i,
      '../../../assets/icons/xls.svg': /\.(xls?|xlsx?)$/i,
    };

    for (const [icon, pattern] of Object.entries(patterns)) {
      if (pattern.test(name)) {
        return icon;
      }
    }

    return '../../../assets/icons/file.svg';
  }

  pickAColor(type: any) {
    switch (type) {
      case 'identification':
      case 'organization_identification':
      case 'offer':
        return '#f0ab96';
        break;
      case 'payroll':
      case 'company_deed':
      case 'offer_signed':
        return '#f0d3b1';
        break;
      case 'employment_record':
      case 'ownership':
      case 'contract_signed':
        return '#a7cce0';
        break;
      case 'bank_account_ownership':
      case 'balance':
      case 'guarantee_contract':
        return '#9daff0';
        break;
      case 'receipt':
      case 'p_and_l':
      case 'guarantee_contract_signed':
        return '#c19fc2';
        break;
      case 'pension_revaluation':
      case '200_organization_income_tax_return':
      case 'delivery_certificate':
        return '#f5f3a8';
        break;
      case '037_census_declaration':
      case '303_vat_return':
      case 'contract':
        return '#ded9d5';
        break;
      case '100_income_tax_return':
      case '390_vat_summary':
        return '#8db99e';
        break;
      case '130_quaterly_income_tax_return':
        return '#4fc3c2';
        break;
      case '131_quaterly_income_tax_return':
        return '#a2d4df';
        break;
        break;
      case 'aux1':
        return '#eaa3b5';
        break;
      case 'aux2':
        return '#c2dbbe';
        break;
      case 'axu3':
        return '#f9f6c1';
        break;
      case 'aux4':
        return '#f7bd98';
      default:
        return '#d0e5e8';
        break;
    }
  }
}
