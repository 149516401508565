import { Component } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { RouterLink } from '@angular/router';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../core/services/notification/notification.service';

interface Notification {
  reference: string;
  type: string;
  title: string;
  content: string;
  url: string;
  expiration_date: string;
  read_status: boolean;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {

  role: any = 'admin';
  environment = environment.production;
  autoFetch: any;
  showNotifications = false;
  notifications: Notification[] = [];
  unreadCount: number = 0;

  constructor(private authService: AuthService, private localStorageService: LocalStorageService, private notificationService: NotificationService) {
    this.role = this.localStorageService.getItem('role');
  }

  ngOnInit(): void {
    this.fetchAllData();
    this.autoFetch = setInterval(() => {
      this.fetchAllData();
    }, 120000);
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData(),

    ]).then(() => {
      this.unreadCount = this.notificationService.countUnreadNotifications(this.notifications);
    });
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.notificationService
        .getData()
        .subscribe({
          next: (data) => {
            this.notifications = data.data;
            this.unreadCount = this.notificationService.countUnreadNotifications(this.notifications);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  markAsRead(reference: string): void {

    this.notificationService.updateStatus(reference, { read: true }).subscribe(
      (response) => {

      },
      (error) => {

      }
    );
    this.notificationService.markAsRead(reference);
    this.unreadCount = this.notificationService.countUnreadNotifications(this.notifications);
  }

  isRead(reference: string): boolean {
    const aux = this.notifications.find(n => n.reference === reference);
    if (aux?.read_status) {
      return true;
    };
    // Verifica si la notificación está en las cookies o si su read_status es 1
    return this.notificationService.isRead(reference) ;
  }

  toggleNotificationsMenu() {
    this.showNotifications = !this.showNotifications;
  }

  logout() {
    this.authService.logout();
  }
}
