<div class="d-flex">
  <div class="document-buttons">
    <div id="btn-general" class="document-button general" [ngClass]="activeForm == 'general' ? 'selected' : ''"
      (click)="changeForm('general')">
      <button type="submit" class="btn-documents">
        <i class="icon-user"></i>
        {{ "forms.buttons.general" | translate }}
      </button>
    </div>

    <div id="btn-doc-order" class="document-button" [ngClass]="activeForm == 'doc-order' ? 'selected' : ''"
      (click)="changeForm('doc-order')">
      <button type="submit" class="btn-documents">
        <i class="icon-files"></i>
        {{ "forms.buttons.doc-order" | translate }}
      </button>
    </div>
  </div>

  <div class="card w-100 form-right">
    <div class="back">
      <h2 class="edit-client">
        {{ "forms.names.editClient" | translate }} {{clientData?.name}}
      </h2>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="form">

          <div class="input-group justify-content-end">
            <div class="clear">
              <button *ngIf="clientData?.assignee" class="btn btn-hover clear-button  ml-15" type="button"
                (click)="openAssigneeForm()">
                {{"forms.inputs.see_assignee" | translate }} {{clientData.client?.assignee.name}} <i
                  class="icon-chevron-right"></i>
              </button>
            </div>
          </div>
          <form [formGroup]="form">
            <div class="form-section mb-3 pb-3" *ngIf="activeForm == 'general'">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="type" class="form-label">{{ "forms.inputs.type" | translate }}
                      <span class="required">*</span>
                    </label>
                    <div class="position-relative">
                      <select id="type" class="form-control" formControlName="type">
                        <option value="organization">
                          {{ "forms.inputs.organization" | translate }}
                        </option>
                        <option value="individual">
                          {{ "forms.inputs.individual" | translate }}
                        </option>
                        <option value="self-employed">
                          {{ "forms.inputs.self-employed" | translate }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row linea"></div>

              <div class="row mb-2" *ngIf="type !== 'organization'">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">{{ "forms.inputs.name" | translate
                      }}<span class="required">*</span></label>
                    <input id="name" class="form-control" formControlName="name"
                      placeholder="{{ 'forms.inputs.name' | translate }}" />
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="lastname" class="form-label">{{
                      "forms.inputs.lastname" | translate
                      }}</label>
                    <input id="lastname" class="form-control" formControlName="lastname"
                      placeholder="{{ 'forms.inputs.lastname' | translate }}" />
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="type === 'organization'">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name" class="form-label">{{ "forms.inputs.name" | translate
                      }}<span class="required">*</span></label>
                    <input id="name" class="form-control" formControlName="name"
                      placeholder="{{ 'forms.inputs.name' | translate }}" />
                  </div>
                </div>
              </div>

              <div class="row linea"></div>

              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="tax_id" class="form-label">{{ "forms.inputs.tax_id" | translate
                      }}<span class="required">*</span></label>
                    <input id="tax_id" class="form-control" formControlName="tax_id"
                      placeholder="{{ 'forms.inputs.tax_id' | translate }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12" *ngIf="type === 'organization'">
                  <div class="mb-3">
                    <label for="client" class="form-label">{{ "forms.inputs.associateClient" | translate }}
                      <span class="required">*</span>
                    </label>
                    <ng-select [items]="filteredClients" bindLabel="displayName" class="select-client-associated"
                      bindValue="reference" formControlName="assignee_id" [searchable]="true" placeholder="{{
                        'forms.inputs.associateClient' | translate
                      }}" [clearable]="true" [class.has-value]="form.get('assignee_id')?.value">
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="row linea"></div>

              <div class="row mb-2">
                <div class="mb-3">
                  <label for="email" class="form-label">{{ "forms.inputs.email" | translate
                    }}<span class="required">*</span></label>
                  <input id="email" class="form-control" formControlName="email"
                    placeholder="{{ 'forms.inputs.email' | translate }}" />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <div class="mb-3">
                    <label for="phone_prefix" class="form-label">{{ "forms.inputs.phone_prefix" | translate }}
                      <span class="required">*</span>
                    </label>
                    <app-select-country-prefix (changePrefix)="changePrefix($event)"
                      [selectedPrefix]="form.get('phonePrefix')?.value">
                    </app-select-country-prefix>
                  </div>
                </div>
                <div class="col-8">
                  <div class="mb-3">
                    <label for="phone" class="form-label">{{ "forms.inputs.phone" | translate }}
                      <span class="required">*</span>
                    </label>
                    <input id="phone" class="form-control phone-number" formControlName="phoneNumber" type="tel"
                      placeholder="{{ 'forms.inputs.phone' | translate }}" (input)="onInput($event)" />
                  </div>
                </div>
              </div>


              <div class="row linea"></div>

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="billing_address" class="form-label">{{
                      "forms.inputs.billing_address" | translate
                      }}</label>
                    <input id="billing_address" class="form-control" formControlName="billing_address" placeholder="{{
                        'forms.inputs.billing_address' | translate
                      }}" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="billing_state" class="form-label">{{
                      "forms.inputs.billing_state" | translate
                      }}</label>
                    <input id="billing_state" class="form-control" formControlName="billing_state" placeholder="{{
                        'forms.inputs.billing_state' | translate
                      }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">{{
                      "forms.inputs.billing_postal_code" | translate
                      }}</label>
                    <input id="name" class="form-control" formControlName="billing_postal_code" placeholder="{{
                        'forms.inputs.billing_postal_code' | translate
                      }}" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="lastname" class="form-label">{{
                      "forms.inputs.billing_city" | translate
                      }}</label>
                    <input id="lastname" class="form-control" formControlName="billing_city" placeholder="{{
                        'forms.inputs.billing_city' | translate
                      }}" />
                  </div>
                </div>
              </div>
            </div>

            <!-- document  -->
            <div *ngIf="activeForm == 'doc-order'">
              <app-form-add-document [client_reference]="clientData.reference" [clientData]="clientData"
                [client]="clientData" [selectTypes]="selectTypes" [activeForm]="activeForm"
                (filesArrayChanged)="actualizarEstadoArchivos($event)"></app-form-add-document>
            </div>
          </form>
        </div>
      </div>
      <div class="div-form"></div>
      <div class="btn-send" *ngIf="activeForm == 'general'">
        <button type="submit" class="btn btn-primary btn-primary-form" (click)="onSubmit()">
          {{ "buttons.update_client" | translate }}
        </button>
      </div>
    </div>
    <!-- end card body -->
  </div>
</div>
