<section class="bg-light py-3 py-md-5 vh-100">
  <div class="container">
    <div class="row justify-content-center login">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="card border border-light-subtle rounded-3 shadow-sm">
          <div class="card-body p-3 p-md-4 p-xl-5">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div>
                <h4 class="password-reset-title mb-2">
                  {{ "login.recover_account" | translate }}
                </h4>
              </div>
              <div class="row gy-2 overflow-hidden mt-3">
                <div class="col-12">
                  <label for="password" class="form-label">{{ "login.new_password" | translate }}</label>
                  <div class="mb-3">
                    <input type="password" class="form-control" id="password" formControlName="password"
                      placeholder="{{ 'login.new_password' | translate }}" required />
                  </div>
                </div>
                <div class="col-12">
                  <label for="password_confirmation" class="form-label">{{ "login.confirm_password" | translate
                    }}</label>
                  <div class="mb-3">
                    <input type="password" class="form-control" id="password_confirmation"
                      formControlName="password_confirmation" placeholder="{{ 'login.confirm_password' | translate }}"
                      required />
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column align-items-center my-3">
                    <button type="submit" class="btn btn-primary btn-login" (click)="onSubmit()">
                      {{ "login.sent_to_recover" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
