import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from '../../core/services/users/users.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { AlertService } from '../../core/services/alert/alert.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule, RouterLink],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
})
export class ProfileComponent {
  showForm = false;
  form: FormGroup;
  userData: any;

  activeForm = 'general';

  showPassword = { current: false, new: false, repeat: false };

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService
  ) {
    this.form = this.fb.group({
      name: [''],
      email: [''],
      role: [''],
      currentPassword: [''],
      newPassword: [''],
      repeatPassword: [''],
    });
  }

  ngOnInit(): void {
    this.fetchUserData();
  }

  loadUserData(reference: string) {
    this.usersService.getUserByReference().subscribe((user) => {
      this.form.patchValue({
        name: user.name,
        email: user.email,
        role: user.role,
      });
    });
  }

  fetchUserData(): void {
    this.usersService.getUserByReference().subscribe(
      (response) => {
        this.userData = response;
        console.log('User Data:', this.userData);

        this.formData(this.userData);
        this.isUserDataLoaded = true;
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  isUserDataLoaded = false;

  updateUser(): void {
    if (this.form.valid && this.isUserDataLoaded) {
      const userReference = this.localStorageService.getItem('user_reference');
      const formData = this.form.value;

      const payload = {
        name: formData.name,
        current_password: formData.currentPassword,
        password: formData.newPassword,
        password_confirmation: formData.repeatPassword,
      };

      if (userReference) {
        this.usersService.updateUser(userReference, payload).subscribe(
          (response) => {
            this.alertService.successOk('swal_messages.users.user_updated');
          },
          (error) => {
            if (
              error.error?.current_password &&
              Array.isArray(error.error.current_password)
            ) {
              const errorMessage =
                'swal_messages.users.current_password_incorrect';
              this.alertService.error(errorMessage);
            } else if (error.error && error.error.password) {
              const passwordError =
                'swal_messages.users.password_confirmation_incorrect';
              this.alertService.error(passwordError);
            } else {
              this.alertService.error('swal_messages.users.user_updated_error');
            }
          }
        );
      } else {
        console.error('Referencia de usuario no encontrada en local storage');
      }
    }
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  cerrarFormulario() {
    this.showForm = false;
  }

  formData(userData: any): void {
    const user = userData.data;

    this.form.patchValue({
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }

  togglePasswordVisibility(field: 'current' | 'new' | 'repeat'): void {
    this.showPassword[field] = !this.showPassword[field];
  }

  hasValue(field: string): boolean {
    return !!this.form.get(field)?.value;
  }
}
