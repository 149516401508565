import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiUrl = environment.apiURL + 'users'; // URL del API
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  // Método para obtener datos de la API
  getUser(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  getUserAgent(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/role-group/2');
  }

  getAllUsers(): Observable<any> {
    const token = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(this.apiUrl, { headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getUserByReference(): Observable<any> {
    const userReference = this.localStorageService.getItem('user_reference');
    const url = `${this.apiUrl}/${userReference}`;

    const token = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(url, { headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateUser(reference: string, userData: any): Observable<any> {
    const url = `${this.apiUrl}/${reference}/update`;
    const token = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.put<any>(url, userData, { headers }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  // LocalStorageService (ejemplo de implementación)
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  validateCurrentPassword(currentPassword: string): Observable<boolean> {
    const url = `${this.apiUrl}/validate-password`;
    return this.http.post<boolean>(url, { password: currentPassword }).pipe(
      catchError((error) => {
        console.error('Error al validar la contraseña actual:', error);
        return [false];
      })
    );
  }
}
