import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatNativeDateModule,
} from '@angular/material/core';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-comission-form',
  standalone: true,
  imports: [ ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule],
  templateUrl: './comission-form.component.html',
  styleUrl: './comission-form.component.css'
})
export class ComissionFormComponent {
  @Input() orderData?: any;
  @Input() ladata: any = [];

  comissionForm: FormGroup = new FormGroup({});

  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService) {
    this.comissionForm = this.fb.group({
      broker_commission_base: ['', Validators.required],
      broker_commission_tax: ['', Validators.required],
      broker_commission_total: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  private addDebouncedValueChange(control: any, callback: () => void): void {
    control?.valueChanges.pipe(debounceTime(300)).subscribe(callback);
  }

  refrescarFormulario() {
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.comissionForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
    }
    this.alertService.close();
  }


  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.comissionForm.controls) {
      if (this.comissionForm.controls.hasOwnProperty(field)) {
        this.comissionForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.comissionForm.get(field)?.enable();
      }
    }

  }

  calculateBrokerComissionFromBase(): void {
    const base = parseFloat(this.comissionForm.get('broker_commission_base')?.value);
    if (!isNaN(base)) {
      const tax = (base * 0.21).toFixed(2);
      const total = (base * 1.21).toFixed(2);

      this.comissionForm
        .get('broker_commission_tax')
        ?.setValue(tax, { emitEvent: false });
      this.comissionForm
        .get('broker_commission_total')
        ?.setValue(total, { emitEvent: false });
    }
  }

  calculateBrokerComissionFromTax(): void {
    const tax = parseFloat(this.comissionForm.get('broker_commission_tax')?.value);
    if (!isNaN(tax)) {
      const base = (tax / 0.21).toFixed(2);
      const total = ((tax / 0.21) * 1.21).toFixed(2);

      this.comissionForm
        .get('broker_commission_base')
        ?.setValue(base, { emitEvent: false });
      this.comissionForm
        .get('broker_commission_total')
        ?.setValue(total, { emitEvent: false });
    }
  }

  calculateBrokerComissionFromTotal(): void {
    const total = parseFloat(this.comissionForm.get('broker_commission_total')?.value);
    if (!isNaN(total)) {
      const base = (total / 1.21).toFixed(2);
      const tax = ((total / 1.21) * 0.21).toFixed(2);

      this.comissionForm
        .get('broker_commission_base')
        ?.setValue(base, { emitEvent: false });
      this.comissionForm
        .get('broker_commission_tax')
        ?.setValue(tax, { emitEvent: false });
    }
  }

  checkUpdateForm():boolean {
    if (this.comissionForm.dirty) {
      return true;
    } else {
      return false;
    }
  }

}
