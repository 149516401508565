import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatNativeDateModule,
} from '@angular/material/core';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { debounceTime } from 'rxjs';
import { LocalStorageService } from '../../../../core/services/localStorage/local-storage.service';

interface AccountData {
  reference: string;
  lastname: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}

@Component({
  selector: 'app-general-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule],
  templateUrl: './general-form.component.html',
  styleUrl: './general-form.component.css'
})
export class GeneralFormComponent {
  @Input() orderData?: any;
  @Input() ladata: any = [];
  @Output() emitSubmit = new EventEmitter<string>();
  account_type = this.localStorageService.getItem('account_type');

  generalForm: FormGroup = new FormGroup({});

  draft = false;
  order_reference: string = '';
  stage?: any;
  newStage: string = '';

  role: any;

  back = "";
  next_ok = "";
  next_ko = "";

  agentData: AccountData[] = [];
  vendorData: AccountData[] = [];
  brokerData: AccountData[] = [];
  supplierData: AccountData[] = [];

  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService, private localStorageService: LocalStorageService,) {
    this.generalForm = this.fb.group({
      broker_id: ['', Validators.required],
      broker_agent: ['', Validators.required],
      broker_agent_id: ['', Validators.required],
      broker_reference: ['', Validators.required],
      notes: ['', Validators.required],
      stage: ['draft', Validators.required],
      supplier_id: ['', Validators.required],
      supplier_agent: ['', Validators.required],
      supplier_agent_id: ['', Validators.required],
      supplier_reference: ['', Validators.required],
      vendor_id: ['', Validators.required],
      vendor_quote_reference: ['', Validators.required],
      vendor_agent_id: ['', Validators.required],
      vendor_reference: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  checkUpdateForm():boolean {
    if (this.generalForm.dirty) {
      return true;
    } else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        this.role = this.localStorageService.getItem('role')
        this.agentData = this.ladata.agentData;
        this.vendorData = this.ladata.vendorData;
        this.brokerData = this.ladata.brokerData;
        this.supplierData = this.ladata.supplierData;
        if (this.account_type == 'broker' && !this.draft) {
          this.generalForm.controls['broker_agent_id'].setValue(
            this.localStorageService.getItem('user_reference')
          );
        }
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  changeStage(event: any) {
    this.newStage = event;
  }

  refrescarFormulario() {
    this.setVariables();
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.generalForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
      if (control) {
        switch (key) {
          case 'broker_agent_id':
            if (this.account_type === 'broker') {
              control.setValue(value);
            } else {
              const brokerAgentName = `${this.orderData?.broker_agent?.name || ''} ${this.orderData?.broker_agent?.lastname || ''}`.trim();
              this.generalForm.get('broker_agent')?.setValue(brokerAgentName);
              control.setValue(value);
            }
            break;
          case 'supplier_agent_id':
            if (this.account_type === 'supplier') {
              control.setValue(value);
            } else {
              const supplierAgentName = `${this.orderData?.supplier_agent?.name || ''} ${this.orderData?.supplier_agent?.lastname || ''}`.trim();
              this.generalForm.get('supplier_agent')?.setValue(supplierAgentName);
              control.setValue(value);
            }
            break;
          default:
            control.setValue(value);
            break;
        }
      }


    }
    this.alertService.close();
  }
  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.generalForm.controls) {
      if (this.generalForm.controls.hasOwnProperty(field)) {
        this.generalForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.generalForm.get(field)?.enable();
      }
    }

  }

  onSubmitOrderFast(stage : string) {
    this.emitSubmit.emit(stage);
  }

  copyToClipboard(value: string, event: Event): void {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  setVariables() {
    this.back = this.orderData?.back || '';
    this.next_ok = this.orderData?.next_ok;
    this.next_ko = this.orderData?.next_ko;
    this.stage = this.orderData?.stage;
    this.draft = this.orderData ? true : false;
    this.order_reference = this.orderData?.reference;
  }
}
