import { Component, inject, ViewChild } from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import {
  MatPaginatorModule,
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { CommonModule, Location } from '@angular/common';
import { OrdersService } from '../../core/services/orders/orders.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormAddOrderComponent } from './form-add-order/form-add-order.component';
import { AlertService } from '../../core/services/alert/alert.service';
import { Orders } from '../../core/interfaces/orders';
import { FormAddDocumentComponent } from './form-add-document/form-add-document.component';
import { FormChangeStatusComponent } from './form-change-status/form-change-status.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsService } from '../../core/services/clients/clients.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { UsersService } from '../../core/services/users/users.service';
import { DocumentsTypeService } from '../../core/services/documentType/documents-type.service';
import { BrandService } from '../../core/services/brand/brand.service';
import { FilterComponent } from './filter/filter.component';
import { Title } from '@angular/platform-browser';
import { FormAddClientComponent } from '../clients/form-add-client/form-add-client.component';
import { FormShowAssigneeComponent } from './form-show-assignee/form-show-assignee.component';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any; // ajusta según la estructura real de root_account si es necesario
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
  // Otros campos posibles como error, message, etc.
}

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    FormAddOrderComponent,
    FormAddDocumentComponent,
    FormChangeStatusComponent,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FilterComponent,
    RouterModule,
    FormAddClientComponent,
    FormShowAssigneeComponent
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})
export class OrdersComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  showAddClientForm = false;
  showAssigneeForm = false;
  showClear = false;
  pageNumber: number = 0;
  pageSize: number = 0;
  order_reference?: any;
  orderData?: any;
  typeOrders: string = 'contracts';
  dataSource = new MatTableDataSource<Orders>();
  title = 'orders';

  searchParams: any = {};
  accountType: string | null = null;

  ladata: any = [];
  cargaladata: any = [];

  document_buttons_chico = false;
  tab = "general";
  displayedColumnsBroker: string[] = [
    'broker_reference',
    'broker_agent',
    'supplier',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'supplier_reference',
    'supplier_agent',
    'broker',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumns: string[] = [];
  type: any = 'broker';
  autoFetch: any;
  constructor(
    private orderService: OrdersService,
    private alertService: AlertService,
    private clientService: ClientsService,
    private location: Location,
    private localStorageService: LocalStorageService,
    private accountService: AccountsService,
    private userService: UsersService,
    private documentTypeService: DocumentsTypeService,
    private brandService: BrandService,
    private clientsService: ClientsService,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }

  ngOnInit() {
    this.detectReference(),
      this.alertService.loading('swal_messages.please_wait');
    this.fetchAllData();
    this.autoFetch = setInterval(() => {
      this.refreshOrdersData();
    }, 40000);
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData(),
      this.fetchData2(),
      this.fetchData3(),
      this.fetchData4(),
      this.fetchData5(),
      this.fetchData6(),
      this.fetchData7(),
      this.fetchData8(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  agentData: AccountData[] = [];
  vendorData: AccountData[] = [];
  brokerData: AccountData[] = [];
  supplierData: AccountData[] = [];
  workshopData: AccountData[] = [];
  dealershipData: AccountData[] = [];
  selectTypes: any[] = [];
  brands: any[] = [];
  models: any[] = [];
  expandedRow: any = null;

  toggleRow(row: any): void {
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
       this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getData(
          this.typeOrders,
          this.pageNumber,
          this.pageSize,
          this.searchParams
        )
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(this.localStorageService.getItem('per_page'));
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
              this.alertService.close();
            setTimeout(() => {
              resolve();
            }, 500); // Espera de 1 segundo antes de resolver la promesa
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve(); // Termina la ejecución y resuelve la promesa si no hay datos
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          this.cargaladata.workshopData = data.filter(
            (item) => item.type === 'workshop'
          );
          this.cargaladata.dealershipData = data.filter(
            (item) => item.type === 'dealership'
          );
          resolve();
        },
        error: (error) => {
          reject(error); // Rechaza la promesa en caso de error
        },
      });
    });
  }
  fetchData3(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.brandService.getData().subscribe({
        next: (data) => {
          this.cargaladata.brands = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData4(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.cargaladata.selectTypes = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData5(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getUserAgent().subscribe({
        next: (data) => {
          this.cargaladata.agentData = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData6(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clientsService.getData().subscribe({
        next: (data) => {
          const updatedResponse = data.data.map((client: any) => {
            return {
              ...client,
              fullname: `${client.name}${client.lastname ? ` ${client.lastname}` : ''}`.trim(),
            };
          });
          this.cargaladata.clientData = updatedResponse;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData7(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getStages().subscribe({
        next: (data) => {
          this.cargaladata.stages = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  fetchData8(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getDeliveryData().subscribe({
        next: (data) => {
          this.cargaladata.delivery_place = data.data.places;
          this.cargaladata.delivery_status = data.data.status;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  getOrder(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path();
    if (!nuevaURL.includes('orders')) {
      const nuevaURL =
        this.location.path() + '/orders/' + this.typeOrders + '/' + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(
        nuevaURL.substring(0, index) +
        '/orders/' +
        this.typeOrders +
        '/' +
        reference
      );
    }
    this.order_reference = reference;
    this.orderService.searchOrder(this.order_reference).subscribe({
      next: (data) => {
        this.orderData = data.data;
        let title = data.data.client?.name ? data.data.client?.name + " " + (data.data.client?.lastname ? data.data.client?.lastname : '') : data.data.broker.reference
        this.titleService.setTitle(this.translate.instant("pages.titles." + this.typeOrders) + " " + title + "- DBM");
        this.showForm = true;
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  getClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        //this.clients = response.data;
      },
      error: (error) => { },
    });
  }

  detectReference() {
    this.route.queryParams.subscribe(params => {
      if (params['tab']) {
        this.tab = params['tab'];
      }
    });



    this.getAccountTypeFromLocalStorage();
    const nuevaURL = this.location.path();
    if (nuevaURL.includes('orders/contracts/add')) {

      this.showForm = true;
    } else {
      if (nuevaURL.includes('orders/contracts')) {
        this.typeOrders = 'contracts';
        const nuevaURL = '/orders/' + this.typeOrders;
        this.location.go(nuevaURL);
        this.title = 'contracts';
      }
      if (nuevaURL.includes('orders/drafts')) {
        this.typeOrders = 'drafts';
        const nuevaURL = '/orders/' + this.typeOrders;
        this.location.go(nuevaURL);
        this.title = 'drafts';
      }
      if (nuevaURL.includes('orders/delivered')) {
        this.typeOrders = 'delivered';
        const nuevaURL = '/orders/' + this.typeOrders;
        this.location.go(nuevaURL);
        this.title = 'delivered';
      }
      if (nuevaURL.includes('orders/after-sales')) {
        this.typeOrders = 'after-sales';
        const nuevaURL = '/orders/' + this.typeOrders;
        this.location.go(nuevaURL);
        this.title = 'after-sales';
      }
      if (nuevaURL.includes('orders/cancelled')) {
        this.typeOrders = 'cancelled';
        const nuevaURL = '/orders/' + this.typeOrders;
        this.location.go(nuevaURL);
        this.title = 'cancelled';
      }
      let paramReference = this.route.snapshot.paramMap.get('reference');
      if (paramReference) {
        this.order_reference = paramReference;
        /*    if (!nuevaURL.includes('orders/contracts')) { */
        const nuevaURL =
          this.location.path() + '/orders/' + this.typeOrders + "/" + paramReference;
        this.location.go(nuevaURL);
        /*  } */
        this.getOrder(paramReference);
      }
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    this.fetchData();
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  cerrarFormulario() {
    this.titleService.setTitle(this.translate.instant("pages.titles." + this.typeOrders) + " - DBM");
    const nuevaURL = this.location.path() + '/orders/' + this.typeOrders;
    const index = nuevaURL.indexOf('/');
    this.location.go(
      nuevaURL.substring(0, index) + '/orders/' + this.typeOrders
    );
    this.assignee = null;
    this.orderData = null;
    this.showForm = false;
    this.showAssigneeForm = false;
    this.document_buttons_chico = false;
  }

  cerrarClienteFormulario() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }

  cerrarAssigneeFormulario() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }
  refrescar() {
    this.fetchData();
  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    }
    return statusColorMap[status] || 'text-new';
  }

  getBadgeColor(stage: string, requiere_action: string, priority: string): string {

    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!requiere_action) {
      return statusColorMap['false'];
    }

    if (requiere_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }


  getAccountTypeFromLocalStorage(): void {
    this.accountType = localStorage.getItem('account_type');
  }

  refreshOrdersData() {


    this.orderService
      .getFilterData(
        this.typeOrders,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(this.localStorageService.getItem('per_page'));
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  /******* EVENTO DEL FILTRO  *******/
  filterChanged(searchTerm: any) {
    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.orderService
      .getFilterData(
        this.typeOrders,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(this.localStorageService.getItem('per_page'));
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }


  openClientForm() {
    this.document_buttons_chico = true;
    this.showAddClientForm = true;
  }
  assignee = null;
  openAssigneeForm(assignee: any) {
    this.assignee = assignee;
    this.document_buttons_chico = true;
    this.showAssigneeForm = true;
  }

  closeClientForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }
  closeAssigneeForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }
  refreshClients() {
    this.assignee = null;
    this.showAddClientForm = false;
  }
}
