<div class="encabezado">
  <div class="col-4 getBack "> <a routerLink="/" class="getBack"> <span><i class="icon-chevron-left"></i>{{
        "forms.buttons.back" | translate }} </span></a></div>
  <div class="">
    <div class="">
      <div>
        <h5 class="titulo1">{{ userData?.data?.name }}</h5>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="profile-card-container">
      <div class="card-item">
        <div class="title-card">{{"profile.personal-data" | translate}} </div>
        <div class="form">
          <form [formGroup]="form">
            <div class="form-section mb-3 pb-3">

              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name" class="form-label">{{ "forms.inputs.name" | translate }}</label>
                    <input id="name" class="form-control" formControlName="name"
                      placeholder="{{ 'forms.inputs.name' | translate }}" />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="mb-3">
                  <label for="email" class="form-label">{{ "profile.email" | translate }}</label>
                  <input id="email" class="form-control" formControlName="email"
                    placeholder="{{ 'forms.inputs.email' | translate }}" readonly />
                </div>
              </div>

              <div class="row mb-2">
                <div class="mb-3">
                  <label for="role" class="form-label">{{ "profile.role" | translate }}</label>
                  <input id="role" class="form-control" formControlName="role"
                    placeholder="{{ 'profile.role' | translate }}" readonly />
                </div>
              </div>

            </div>
          </form>

        </div>
      </div>

      <!--       <div class="card-item pl-4">
        <div class="title-card">{{"profile.photo" | translate}} </div>
        <div class="form">
          <form [formGroup]="form">
            <div class="form-section mb-3 pb-3">
              <div class="row mb-2 profile-image">
                <img src="../../../assets/images/user-empty.png" alt="" />
                <i class="icon-pencil icon-profile-pencil"></i>
              </div>
            </div>
          </form>
          <div class="button-change-password mt-2">
            <button class="password-change">
              {{"profile.change-image" | translate}}
            </button>
          </div>
        </div>
      </div> -->

      <div class="card-item">
        <div class="title-card">{{"profile.change-password" | translate}} </div>
        <div class="form">
          <form [formGroup]="form">
            <div class="form-section mb-3 pb-3">

              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="currentPassword" class="form-label">{{ "profile.current-password" | translate }}</label>
                    <div class="input-password-container">
                      <input id="currentPassword" class="form-control"
                        [type]="showPassword.current ? 'text' : 'password'" formControlName="currentPassword"
                        placeholder="{{ 'profile.current-password' | translate }}" />
                      <span *ngIf="hasValue('currentPassword')" (click)="togglePasswordVisibility('current')"
                        class="icon" [class.icon-eye-crossed]="!showPassword.current"
                        [class.icon-eye]="showPassword.current"></span>
                    </div>
                    <div *ngIf="form.get('currentPassword')?.invalid && form.get('currentPassword')?.touched">
                      <small class="text-danger">Este campo es obligatorio.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="newPassword" class="form-label">{{ "profile.new-password" | translate }}</label>
                    <div class="input-password-container">
                      <input id="newPassword" class="form-control" [type]="showPassword.new ? 'text' : 'password'"
                        formControlName="newPassword" placeholder="{{ 'profile.new-password' | translate }}" />
                      <span *ngIf="hasValue('newPassword')" (click)="togglePasswordVisibility('new')" class="icon"
                        [class.icon-eye-crossed]="!showPassword.new" [class.icon-eye]="showPassword.new"></span>
                    </div>
                    <div *ngIf="form.get('newPassword')?.invalid && form.get('newPassword')?.touched">
                      <small class="text-danger">Este campo es obligatorio.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="repeatPassword" class="form-label">{{ "profile.repeat-password" | translate }}</label>
                    <div class="input-password-container">
                      <input id="repeatPassword" class="form-control" [type]="showPassword.repeat ? 'text' : 'password'"
                        formControlName="repeatPassword" placeholder="{{ 'profile.repeat-password' | translate }}" />
                      <span *ngIf="hasValue('repeatPassword')" (click)="togglePasswordVisibility('repeat')" class="icon"
                        [class.icon-eye-crossed]="!showPassword.repeat" [class.icon-eye]="showPassword.repeat"></span>
                    </div>
                    <div
                      *ngIf="form.get('repeatPassword')?.errors?.['passwordMismatch']  && form.get('repeatPassword')?.touched">
                      <small class="text-danger">Las contraseñas no coinciden.</small>
                    </div>
                    <div
                      *ngIf="form.get('repeatPassword')?.errors?.['passwordMismatch'] && form.get('repeatPassword')?.touched">
                      <small class="text-danger">Las contraseñas no coinciden.</small>
                    </div>


                  </div>
                </div>
              </div>

            </div>
          </form>

          <div class="button-change-password">
            <button class="password-change" (click)="updateUser()">
              {{"profile.change-password" | translate}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="overlay" *ngIf="showForm"></div>