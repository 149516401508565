<div class="document-buttons" [ngClass]="{ 'document-buttons-chico': document_buttons_chico }">
  <div *ngIf="!draft" id="btn-general" class="document-button" [ngClass]="activeForm == 'general' ? 'selected' : ''"
    (click)="emitchangeForm('general')">
    <button type="submit" class="btn-documents">
      <i class="icon-clipboard-empty"></i>
      {{ "forms.buttons.new" | translate }}
      <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
    </button>
  </div>
  <div *ngIf="draft" id="btn-general" class="document-button" [ngClass]="activeForm == 'general' ? 'selected' : ''"
    (click)="emitchangeForm('general')">
    <button type="submit" class="btn-documents">
      <i class="icon-clipboard-empty"></i>
      {{ "forms.buttons.general" | translate }}
      <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
    </button>
  </div>
  <div *ngIf="draft">
    <div id="btn-vehicle" class="document-button" [ngClass]="activeForm == 'vehicle' ? 'selected' : ''"
      (click)="emitchangeForm('vehicle')">
      <button type="submit" class="btn-documents">
        <i class="icon-car"></i>
        {{ "forms.buttons.vehicle" | translate }}
        <span *ngIf="updatesTabs.includes('vehicle')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-currency" class="document-button" [ngClass]="activeForm == 'currency' ? 'selected' : ''"
      (click)="emitchangeForm('currency')">
      <button type="submit" class="btn-documents">
        <i class="icon-bag-dollar"></i>
        {{ "forms.buttons.currency" | translate }}
        <span *ngIf="updatesTabs.includes('currency')" class="violet-dot"></span>
      </button>
    </div>

    <div id="btn-client" class="document-button" [ngClass]="activeForm == 'client' ? 'selected' : ''"
      (click)="emitchangeForm('client')">
      <button type="submit" class="btn-documents">
        <i class="icon-user"></i>
        {{ "forms.buttons.client" | translate }}
        <span *ngIf="updatesTabs.includes('client')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-guarantee" class="document-button" [ngClass]="activeForm == 'guarantee' ? 'selected' : ''"
      (click)="emitchangeForm('guarantee')">
      <button type="submit" class="btn-documents">
        <i class="icon-user-lock"></i>
        {{ "forms.buttons.guarantee" | translate }}
        <span *ngIf="updatesTabs.includes('guarantee')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-doc-order" class="document-button" [ngClass]="activeForm == 'doc-order' ? 'selected' : ''"
      (click)="emitchangeForm('doc-order')">
      <button type="submit" class="btn-documents">
        <i class="icon-files"></i>
        {{ "forms.buttons.doc-order" | translate }}
        <span *ngIf="updatesTabs.includes('doc-order')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-risk" class="document-button" [ngClass]="activeForm == 'risk' ? 'selected' : ''"
      (click)="emitchangeForm('risk')">
      <button type="submit" class="btn-documents">
        <i class="icon-chart-bars"></i>
        {{ "forms.buttons.risk" | translate }}
        <span *ngIf="updatesTabs.includes('risk')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-delivery" class="document-button" [ngClass]="activeForm == 'delivery' ? 'selected' : ''"
      (click)="emitchangeForm('delivery')">
      <button type="submit" class="btn-documents">
        <i class="icon-calendar-insert"></i>
        {{ "forms.buttons.delivery" | translate }}
        <span *ngIf="updatesTabs.includes('delivery')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-notes" class="document-button" [ngClass]="activeForm == 'notes' ? 'selected' : ''"
      (click)="emitchangeForm('notes')">
      <button type="submit" class="btn-documents">
        <i class="icon-register"></i>
        {{ "forms.buttons.notes" | translate }}
        <span *ngIf="updatesTabs.includes('notes')" class="violet-dot"></span>
      </button>
    </div>
    <div *ngIf="role =='Supervisor'" id="btn-comission" class="document-button"
      [ngClass]="activeForm == 'comission' ? 'selected' : ''" (click)="emitchangeForm('comission')">
      <button type="submit" class="btn-documents">
        <i class="icon-percent"></i>
        {{ "forms.buttons.comission" | translate }}
        <span *ngIf="updatesTabs.includes('comission')" class="violet-dot"></span>
      </button>
    </div>
    <div id="btn-timeline" class="document-button" [ngClass]="activeForm == 'timeline' ? 'selected' : ''"
      (click)="emitchangeForm('timeline')">
      <button type="submit" class="btn-documents">
        <i class="icon-align-left"></i>
        {{ "forms.buttons.timeline" | translate }}
        <span *ngIf="updatesTabs.includes('timeline')" class="violet-dot"></span>
      </button>
    </div>
  </div>
</div>
