import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatNativeDateModule,
} from '@angular/material/core';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { debounceTime } from 'rxjs';


@Component({
  selector: 'app-contract-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule],
  templateUrl: './contract-form.component.html',
  styleUrl: './contract-form.component.css'
})
export class ContractFormComponent {
  @Input() orderData?: any;
  @Input() ladata: any = [];

  contractForm: FormGroup = new FormGroup({});
  type_ :any[] = ['renting', 'leasing'];

  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService) {
    this.contractForm = this.fb.group({
      duration: ['12', Validators.required],
      include_insurance: [false, Validators.required],
      include_maintenance: [false, Validators.required],
      include_tires: [false, Validators.required],
      initial_payment: ['', Validators.required],
      monthly_fee_base: ['', Validators.required],
      monthly_fee_tax: ['', Validators.required],
      monthly_fee_total: ['', Validators.required],
      total_kms: ['', Validators.required],
      type: ['renting', Validators.required],
      yearly_kms: [''],
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.addDebouncedValueChange(this.contractForm.get('total_kms'), () =>
      this.calculateTotalKms()
    );
    this.addDebouncedValueChange(this.contractForm.get('yearly_kms'), () =>
      this.calculateAnualKms()
    );
    this.addDebouncedValueChange(this.contractForm.get('monthly_fee_base'), () =>
      this.calculateMonthlyFeesFromBase()
    );
    this.addDebouncedValueChange(this.contractForm.get('monthly_fee_tax'), () =>
      this.calculateMonthlyFeesFromTax()
    );
    this.addDebouncedValueChange(this.contractForm.get('monthly_fee_total'), () =>
      this.calculateMonthlyFeesFromTotal()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {

        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  private addDebouncedValueChange(control: any, callback: () => void): void {
    control?.valueChanges.pipe(debounceTime(300)).subscribe(callback);
  }

  refrescarFormulario() {
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.contractForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
    }
    this.alertService.close();
  }

  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.contractForm.controls) {
      if (this.contractForm.controls.hasOwnProperty(field)) {
        this.contractForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.contractForm.get(field)?.enable();
      }
    }

  }

  calculateTotalKms(): void {
    const months = parseInt(this.contractForm.get('duration')?.value);
    const kms = parseInt(this.contractForm.get('total_kms')?.value);
    if (!isNaN(kms) && !isNaN(months)) {
      const base = Math.round((kms / months) * 12);

      this.contractForm.get('yearly_kms')?.setValue(base, { emitEvent: false });
    }
  }

  calculateAnualKms(): void {
    const months = parseInt(this.contractForm.get('duration')?.value);
    const kms = parseInt(this.contractForm.get('yearly_kms')?.value);
    if (!isNaN(kms) && !isNaN(months)) {

      const base = Math.round(kms * (months / 12));

      this.contractForm.get('total_kms')?.setValue(base, { emitEvent: false });
    }
  }

  calculateKms(): void {
    if (this.orderData?.total_kms) {
      this.contractForm
        .get('total_kms')
        ?.setValue(this.orderData?.total_kms, { emitEvent: false });
      this.calculateTotalKms();
    } else {
      this.contractForm.get('total_kms')?.setValue('', { emitEvent: false });
      this.contractForm.get('yearly_kms')?.setValue('', { emitEvent: false });
    }
  }
  calculateMonthlyFeesFromBase(): void {
    const base = parseFloat(this.contractForm.get('monthly_fee_base')?.value);
    if (!isNaN(base)) {
      const tax = (base * 0.21).toFixed(2);
      const total = (base * 1.21).toFixed(2);

      this.contractForm.get('monthly_fee_tax')?.setValue(tax, { emitEvent: false });
      this.contractForm.get('monthly_fee_total')?.setValue(total, { emitEvent: false });
    }
  }

  calculateMonthlyFeesFromTax(): void {
    const tax = parseFloat(this.contractForm.get('monthly_fee_tax')?.value);
    if (!isNaN(tax)) {
      const base = (tax / 0.21).toFixed(2);
      const total = ((tax / 0.21) * 1.21).toFixed(2);

      this.contractForm.get('monthly_fee_base')?.setValue(base, { emitEvent: false });
      this.contractForm.get('monthly_fee_total')?.setValue(total, { emitEvent: false });
    }
  }

  calculateMonthlyFeesFromTotal(): void {
    const total = parseFloat(this.contractForm.get('monthly_fee_total')?.value);
    if (!isNaN(total)) {
      const base = (total / 1.21).toFixed(2);
      const tax = ((total / 1.21) * 0.21).toFixed(2);

      this.contractForm.get('monthly_fee_base')?.setValue(base, { emitEvent: false });
      this.contractForm.get('monthly_fee_tax')?.setValue(tax, { emitEvent: false });
    }
  }

  checkUpdateForm():boolean {
    if (this.contractForm.dirty) {
      return true;
    } else {
      return false;
    }
  }
}
