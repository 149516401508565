
<div *ngIf=" orderData.rules?.guarantee_id=='w'">
  <form [formGroup]="guaranteeForm">
  <div class="row">
    <div class="col-6">
      <div class="mb-5 buscador d-block">
        <label for="guarantee" class="form-label">{{
          "forms.inputs.guarantee" | translate
          }}</label>
        <div class="client-selector">
          <div class="main-selector-container">
            <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleGuarantee}"
              [ngClass]="{ 'disabled-select': isGuaranteeInputDisabled}">
              <input type="text" (focus)="onFocus('guarantee')" (blur)="hideOptions()"
                placeholder="{{'forms.inputs.guarantee' | translate }}"
                (input)="searchClients($event.target)" [value]="displayTextGuarantee"
                [disabled]="isGuaranteeInputDisabled" />
              <input type="hidden" formControlName="guarantee_id" />
              <span class="icon-chevron-down arrow"></span>
            </div>
            <div class="options-container" *ngIf="optionsVisibleGuarantee"
              [ngClass]="{ visible: optionsVisibleGuarantee}">
              <div class="option-value" *ngFor="let client of filteredGuarantee" (mousedown)="
                  onSelectGuarantee(client)
                ">
                <div>
                  {{client.name}} {{client.lastname? client.lastname : ''}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="client_selector_spinner" class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <label *ngIf="!orderData.guarantee" for="client" class="text_add"
          (click)="openClientForm()">{{ "forms.inputs.new_client" | translate }}
        </label>
      </div>
    </div>
    <div class="col-6">
      <div class="mb-3">
        <label for="guarantee_relationship" class="form-label">{{
          "forms.inputs.guarantee_relationship" | translate
          }}</label>
        <input type="text" class="form-control" id="guarantee_relationship" required
          formControlName="guarantee_relationship" placeholder="{{
            'forms.inputs.guarantee_relationship' | translate
          }}" />
      </div>
    </div>
  </div>
</form>
</div>


<div class="row" *ngIf="orderData.guarantee">
  <div class="col-6">
    <div class="column-list">
      <label>{{
        "forms.inputs.guarantee" | translate
        }}</label>
      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled
          [value]="orderData.guarantee?.name + ' ' + orderData.guarantee?.lastname" #inputClientName />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputClientName.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <div class="input-group justify-content-end">
        <div class="clear">
          <button *ngIf="orderData.guarantee?.assignee" class="btn btn-hover clear-button  ml-15" type="button"
            (click)="openAssigneeForm()">
            {{"forms.inputs.see_assignee" | translate }} {{orderData.guarantee?.assignee.name}} <i
              class="icon-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="column-list">
      <label>{{
        "forms.inputs.identification" | translate
        }}</label>
      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.tax_id" #inputFieldtax_id />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldtax_id.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="column-list">
      <label>{{ "forms.inputs.contact" | translate }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.email" #inputFieldemail />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldemail.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{ "forms.inputs.phone" | translate }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.phone" #inputFieldphone />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldphone.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="column-list">
      <label>{{
        "forms.inputs.direction" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.billing_address"
          #inputFieldbilling_address />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_address.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>

      <label>{{
        "forms.inputs.billing_postal_code" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.billing_postal_code "
          #inputFieldbilling_postal_code />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_postal_code.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{
        "forms.inputs.billing_state" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.billing_state"
          #inputFieldbilling_state />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_state.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{
        "forms.inputs.billing_city" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.guarantee?.billing_city"
          #inputFieldbilling_city />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_city.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
