import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../../core/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
})
export class ResetPasswordComponent {
  form: FormGroup;
  token: string | null = null;
  email: string | null = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]],
      },
      { validators: this.passwordMatchValidator }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.email = params['email'];
    });
  }

  passwordMatchValidator(group: FormGroup): { [key: string]: boolean } | null {
    const password = group.get('password')?.value;
    const passwordConfirmation = group.get('password_confirmation')?.value;
    return password === passwordConfirmation ? null : { mismatch: true };
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const { password, password_confirmation } = this.form.value;

    this.alertService.loading('swal_messages.please_wait');

    this.authService
      .resetPassword({
        token: this.token,
        email: this.email,
        password,
        password_confirmation,
      })
      .subscribe({
        next: () => {
          this.alertService.successOk('swal_messages.complete_success_reset');
          this.router.navigate(['/login']);
        },
        error: (error) => {
          this.alertService.error('swal_messages.error_reset');
        },
        complete: () => {
          this.alertService.close();
        },
      });
  }
}
