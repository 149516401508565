import { CommonModule } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../core/services/alert/alert.service';
import { OrdersService } from '../../core/services/orders/orders.service';
import { updatePipe, updatePipe2, updatePipe3, updatePipe4 } from '../../core/pipe/custom-pipes';
import { FormAddNotesComponent } from '../orders/form-add-notes/form-add-notes.component';
import {
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';


interface Event {
  created_by: string;
  message: string;
  time: string;
  values: string[];
}

interface Item {
  date: string;
  events: Event[];
}
@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    updatePipe,
    updatePipe2,
    updatePipe3,
    updatePipe4,
    FormAddNotesComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.css',
})
export class TimelineComponent {
  @Input() order_reference?: any;
  timelineItems: any[] = [];
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private orderService: OrdersService,
    private alertService: AlertService
  ) {
    this.form = this.fb.group({
      order_id: [''],
      public: [true],
      content: [''],
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order_reference']) {
      this.form.patchValue({
        order_id: this.order_reference,
      });
      this.getTimeline(this.order_reference);
    } else {
    }
  }

  formatDate(fecha: string): string {
    const date = new Date(fecha);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  getTimeline(order_reference: string = this.order_reference) {
    if (order_reference) {
      this.order_reference = order_reference;
    }
    this.alertService.loading('swal_messages.please_wait');
    this.orderService.getTimeline(this.order_reference).subscribe({
      next: (data) => {
        // let aux = this.timelineItems;
        this.timelineItems = data.data;
        // this.timelineItems.unshift(aux[0]);
        this.timelineItems.sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        this.timelineItems = this.combineEventsByDate(this.timelineItems);
        this.timelineItems = this.sortItemsByDateDesc(this.timelineItems);
        this.timelineItems.forEach((day :Item) => {
          day.events.sort((a, b) => {
              return   this.timeToNumber(b.time) - this.timeToNumber(a.time)
          });
      });
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.close();
      },
    });
  }

   timeToNumber(time:any) {
    return parseInt(time.replace(/:/g, ''));
}
  combineEventsByDate(items: Item[]): Item[] {
    const combined: Item[] = items.reduce((acc: Item[], item: Item) => {
      const existingItem = acc.find(i => i.date === item.date);
      if (existingItem) {
        existingItem.events = [...existingItem.events, ...item.events];
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, []);

    return combined;
  }

  sortItemsByDateDesc(items: Item[]): Item[] {
    return items.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
  }  getIcon(icon: string) {
    switch (icon) {
      case 'notes_risk':
        return 'icon-clipboard-alert';
      case 'notes':
        return 'icon-clipboard-pencil';
      case 'updates':
        return 'icon-pencil';
      case 'status':
        return 'icon-sync';
      case 'stages':
        return 'icon-sync';
      case 'creation':
        return 'icon-plus-circle';
      case 'documents':
        return 'icon-upload';
      default:
        return 'icon-pencil';
    }
  }
}
