import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiURL; // URL del API
  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  login(credentials: { email: string; password: string }) {
    return this.http.post<any>(this.apiUrl + 'auth', credentials).pipe(
      tap((response) => {
        const expires_in = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.localStorageService.setItem(
          'expires_in',
          expires_in.getTime().toString()
        );
        this.localStorageService.setItem('access_token', response.access_token);

        // Extraer y almacenar datos personalizados del token JWT
        const userData = this.parseJwt(response.access_token);
        this.localStorageService.setItem('name', userData.name);
        this.localStorageService.setItem('role', userData.role);
        this.localStorageService.setItem('account_type', userData.account_type);
        this.localStorageService.setItem('account_name', userData.account_name);
        this.localStorageService.setItem(
          'account_reference',
          userData.account_reference
        );
        this.localStorageService.setItem(
          'user_reference',
          userData.user_reference
        );
      })
    );
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  logout(): void {
    this.localStorageService.reset();
    this.router.navigate(['/login']);
  }

  //SetUser
  private user: any;
  getUserData(): Observable<any> {
    const token = this.localStorageService.getItem('access_token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = this.apiUrl + 'users';
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        this.user = response;
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setUser(userData: any): void {
    this.user = userData;
    this.localStorageService.setItem('user', JSON.stringify(userData));
  }

  getUser(): any {
    if (!this.user) {
      this.user = JSON.parse(
        this.localStorageService.getItem('user') || 'null'
      );
    }
    return this.user;
  }

  getUserEmail(): string | null {
    return this.localStorageService.getItem('user_email');
  }

  private apiUrlEmail = `${environment.apiURL}password/send-reset-link`;

  sendResetLink(email: string): Observable<any> {
    return this.http.post(this.apiUrlEmail, { email });
  }

  private apiUrlResetPassword = `${environment.apiURL}password/reset`;

  resetPassword(data: {
    token: string | null;
    email: string | null;
    password: string;
    password_confirmation: string;
  }): Observable<any> {
    return this.http.post(this.apiUrlResetPassword, data);
  }
}
