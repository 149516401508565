import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ExtrasService } from '../extras/extras.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private translate: TranslateService,
    private extrasService: ExtrasService
  ) {}

  public async successOk(
    messageKey: string = 'swal_messages.complete_success',
    titleKey: string = 'swal_messages.success'
  ): Promise<any> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    return Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    });
  }

  public async success(
    messageKey: string = 'swal_messages.complete_success',
    titleKey: string = 'swal_messages.success'
  ): Promise<any> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    return Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      timer: 2000, // 2 segundos
      showConfirmButton: false, // Ocultar el botón de confirmación
    });
  }

  public async error(
    messageKey: any = 'swal_messages.error_occurred',
    extraErrorData: any = '',
    titleKey: string = 'swal_messages.error'
  ): Promise<any> {
    let missingPropertiesKeys: any = [];
    var errorKeys: any = [];

    if (messageKey.hasOwnProperty('missing_properties')) {
      // missingPropertiesKeys = messageKey.missing_properties;
      const missingProperties = messageKey.missing_properties;
      // Extraer el resto del objeto sin missing_properties
      const { missing_properties, ...resto } = messageKey;
      // Asignar las variables
      const otherErrors = resto;
      missingPropertiesKeys = missingProperties;
      errorKeys = otherErrors;
    } else {
      errorKeys = messageKey;
    }
    let message: string | undefined;
    let extraMessage: any = '';

    if (typeof errorKeys === 'string') {
      message = await this.translate.get(errorKeys).toPromise();
    }

    if (typeof errorKeys === 'object') {
      message = await this.extrasService.traducirError(errorKeys);
    }
    if (missingPropertiesKeys.length > 0) {
      extraMessage = await this.extrasService.traducirError2(
        missingPropertiesKeys
      );
    }

    if (extraErrorData != '') {
      extraMessage = await Promise.all(
        extraErrorData.map(async (item: string) => {
          const translatedItem = await this.translate.get(item).toPromise();
          return translatedItem;
        })
      );
      extraMessage.join(', ');
    }

    //const extraMessage = await this.translate.get(extraErrorData).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    let mensaje = message + ' ' + extraMessage;
    mensaje = mensaje.split('\n').map(line => `<span> ${line}</span>`).join('<br>');
    return Swal.fire({
      title: title,
      html: mensaje,
      icon: 'error',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Cerrar',
    });
  }

  public async error2(
    messageKey: any = 'swal_messages.error_occurred',
    extraErrorData: any = '',
    titleKey: string = 'swal_messages.error'
  ): Promise<any> {
    let message: string | undefined;
    let extraMessage: any = '';
    let missingPropertiesKeys: any = [];
    var errorKeys: any = [];
    if (messageKey.hasOwnProperty('missing_properties')) {
      // missingPropertiesKeys = messageKey.missing_properties;
      const missingProperties = messageKey.missing_properties;

      // Extraer el resto del objeto sin missing_properties
      const { missing_properties, ...resto } = messageKey;

      // Asignar las variables
      const otherErrors = resto;
      missingPropertiesKeys = missingProperties;
      errorKeys = otherErrors;
    }
    if (typeof errorKeys === 'object') {
      message = await this.extrasService.traducirError2(errorKeys);
    }
    if (missingPropertiesKeys.length > 0) {
      extraMessage = await this.extrasService.traducirError2(
        missingPropertiesKeys
      );
    }

    const title = await this.translate.get(titleKey).toPromise();
    return Swal.fire({
      title: title,
      text: message + ' ' + extraMessage,
      icon: 'error',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Cerrar',
    });
  }

  public async warning(
    messageKey: string = 'swal_messages.warning_confirm',
    titleKey: string = 'swal_messages.warning'
  ): Promise<any> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      confirmButtonColor: '#ffc107',
      confirmButtonText: 'Entendido',
    });
  }

  public async confirmation(
    messageKey: string = 'swal_messages.are_you_sure_confirm',
    titleKey: string = 'swal_messages.confirm',
    confirmButtonTextkey: string = 'swal_messages.confirm',
    cancelButtonTextkey: string = 'swal_messages.cancel'
  ): Promise<any> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    const confirmButtonText = await this.translate
      .get(confirmButtonTextkey)
      .toPromise();
    const cancelButtonText = await this.translate
      .get(cancelButtonTextkey)
      .toPromise();
    return Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText,
      cancelButtonText,
    });
  }
  public async confirmation2(
    messageKey: string = 'swal_messages.are_you_sure_confirm',
    titleKey: string = 'swal_messages.confirm',
    confirmButtonTextkey: string = 'swal_messages.confirm',
    cancelButtonTextkey: string = 'swal_messages.cancel'
  ): Promise<any> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    const confirmButtonText = await this.translate
      .get(confirmButtonTextkey)
      .toPromise();
    const cancelButtonText = await this.translate
      .get(cancelButtonTextkey)
      .toPromise();
    return Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText,
      cancelButtonText,
    });
  }

  public async loading(
    messageKey: string = 'swal_messages.please_wait',
    titleKey: string = 'swal_messages.loading'
  ): Promise<void> {
    const message = await this.translate.get(messageKey).toPromise();
    const title = await this.translate.get(titleKey).toPromise();
    Swal.fire({
      title: title,
      text: message,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  public close(): void {
    Swal.close();
  }
}
