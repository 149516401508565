<section class="bg-light py-3 py-md-5 vh-100">
  <div class="container">
    <div class="row justify-content-center login">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="card border border-light-subtle rounded-3 shadow-sm">
          <div class="card-body p-3 p-md-4 p-xl-5">
            <div class="login-logo">
              <img src="assets/images/logo-dark.png" class="img-fluid" alt="" height="22" />
            </div>
            <form [formGroup]="form">
              <div class="row gy-2 overflow-hidden login">
                <div class="col-12">
                  <label for="email" class="form-label">{{
                    "login.email" | translate
                    }}</label>
                  <div class="mb-3">
                    <input type="email" class="form-control" name="email" id="email" formControlName="email"
                      placeholder="{{ 'login.email' | translate }}" required />
                  </div>
                </div>
                <div class="col-12">
                  <label for="password" class="form-label">{{
                    "login.password" | translate
                    }}</label>
                  <div class="mb-3">
                    <input type="password" class="form-control" name="password" id="password" formControlName="password"
                      value="" placeholder="{{ 'login.password' | translate }}" required />
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex gap-2 justify-content-between">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" name="rememberMe" id="rememberMe" />
                      <label class="form-check-label text-secondary" for="rememberMe">
                        {{ "login.remember" | translate }}
                        <menu type="context"></menu>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column align-items-center my-3">
                    <button type="submit" class="btn btn-primary btn-login" (click)="onLogin()">
                      {{ "buttons.login" | translate }}
                    </button>
                    <a href="/recover-password" class="link-primary text-decoration-none mt-3 forgot">{{ "login.forgot"
                      |
                      translate }}</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>